<!--
    @name: formDataList
    @description：formDataList
    @author: ZengWei
    @date: 2022-04-24 11:46
-->
<template>
  <div class="form-data-list">
    <FormDataList
      v-if="pageId"
      :moduleUuid="pageId"
      :formFillData="formFillData"
    ></FormDataList>
  </div>
</template>

<script>
import FormDataList from "@/views/subitem/formDataList/index.vue"
export default {
  name: "formDataList",
  components: {
    FormDataList
  },
  props: {},
  data() {
    return {
      pageId: this.$route.query.module_uuid || 'formModule62622152d857b',
      formFillData:{}
    }
  },
  methods: {},
}
</script>

<style lang="less" scoped>
.form-data-list{
  height: 100%;
}
</style>
