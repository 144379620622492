<!--
    @name: store
    @description：store
    @author: ZengWei
    @date: 2021-12-16 17:06
-->
<template>
	<div>
    <PcParser
      v-if="formData"
      ref="formParser"
      :form-data="formData"
      :need-flow="!!formData.need_flow"
      :module-uuid="moduleUuid"
      form-mode="add"
      :displayData="editData"
      :fillData="formFillData"
      @submit="handleSubmit"
      @on-cancel="handleCancel"
    >
    </PcParser>
		<skeleton v-else></skeleton>
	</div>
</template>

<script>
	import {formDesign} from "@/apis/data/workflow";
  import PcParser from "@/custom-component/form/newParser/PcParser";
	import skeleton from "@/custom-component/form/newParser/skeleton";
	export default {
		name: "store",
		components: {PcParser,skeleton},
		props: ['moduleUuid','dataId', 'formFillData'],
		data() {
			return {
				editData: {},
        formData: null
			}
		},
		mounted() {
      this.initParser()
		},
		methods: {
      initParser(){
        formDesign.formShow(this.moduleUuid).then(res=>{
          const designData = JSON.parse(res.data.data.json);
          if(res.data.code === 200){
            if(this.dataId) {
              formDesign.formDataDetail(this.dataId).then(resp=>{
                if(resp.data.code === 200){
                  this.editData = resp.data.data;
                  designData.need_flow = false;
                  this.formData = designData
                }
              });
            } else {
              designData.need_flow = !!designData.need_flow;
              this.formData = designData
            }
          }
        })
      },
      triggerSubmit(){
        this.$refs.formParser.handleSubmit()
      },
			handleSubmit(formSubmitData){
				this.$emit('on-submit',formSubmitData,this.dataId)
			},
			handleCancel(){
				this.$emit('on-cancel')
			},
		},
	}
</script>

<style scoped>

</style>
