<!--
    @name: detail
    @description：detail
    @author: ZengWei
    @date: 2021-12-16 17:06
-->
<template>
	<div>
		<PcParser
			v-if="dataId && formData && editData"
			ref="formParser"
			:form-data="formData"
			:displayData="editData"
			:module-uuid="moduleUuid"
			:need-flow="!!formData.need_flow"
			form-mode="detail"
			:disabled="true"
			:hide-btn="true"
			:fillData="formFillData"
		>
		</PcParser>

		<skeleton v-else></skeleton>
	</div>
</template>

<script>
	import {formDesign} from "@/apis/data/workflow";
  import PcParser from "@/custom-component/form/newParser/PcParser";
	import skeleton from "@/custom-component/form/newParser/skeleton";
	export default {
		name: "detail",
		components: {PcParser,skeleton},
		props: ['moduleUuid','dataId','formFillData'],
		data() {
			return {
				editData: null,
        formData: {},
			}
		},
		created() {
			this.getDetailData();
		},
		methods: {
			getDetailData(){
        formDesign.formShow(this.moduleUuid).then(res=>{
          if(res.data.code === 200){
            const designData = JSON.parse(res.data.data.json);
            formDesign.formDataDetail(this.dataId).then(res=>{
              if(res.data.code === 200){
                this.editData = res.data.data;
                this.formData = designData
              }
            });
          }
        })
			}
		},
	}
</script>

<style scoped>

</style>
